import { render, staticRenderFns } from "./CreateOrg.vue?vue&type=template&id=25c87896&scoped=true"
import script from "./CreateOrg.vue?vue&type=script&lang=js"
export * from "./CreateOrg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c87896",
  null
  
)

export default component.exports